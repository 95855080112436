<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols='12'>
          <v-card-title >
            6. Воспламеняющиеся жидкости
          </v-card-title>
          <v-card-text class="title font-weight-light">
            Воспламеняющиеся жидкости по температуре вспышки делятся на легковоспламеняющиеся (ЛВЖ) и горючие жидкости (ГЖ).
          </v-card-text >
          <v-row>
            <v-col cols="6">
              <v-card>
                <v-card-text class="title font-weight-light mb-0">
                  <p><span class="red--text font-weight-bold">Горючие жидкости</span> {{ value1 }} Например: мазут, дизельное топливо, масла, лаки и др.;</p>
                  <p>ГЖ являются взрывоопасными, если нагреты до температуры вспышки.</p>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-card>
                <v-card-text class="title font-weight-light mb-0">
                  <p><span class="red--text font-weight-bold">Температура вспышки </span>– минимальная температура, при которой пары над нагретой поверхностью вспыхивают при поднесении к ним источника зажигания.</p>
                  <p>Устойчивое горение не наблюдается.</p>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="7">
              <v-card-text class="title">
                Классификация ЛВЖ по разрядам согласно ГОСТ 19433-88:
              </v-card-text>
              <v-card-text class="d-flex align-center title font-weight-light pa-0">
                <v-icon size="150" color="red">mdi-roman-numeral-1</v-icon>
                <div>
                  <span>особо опасные, <strong>t<sub>всп</sub> = &lt; -18 °С</strong> <br> (ацетон, бензин, эфир и др.);</span>
                </div>
              </v-card-text>
              <v-card-text class="d-flex align-center title font-weight-light pa-0">
                <v-icon size="150" color="red">mdi-roman-numeral-2</v-icon>
                <div>
                  <span>постоянно опасные,<strong> t<sub>всп</sub>=(-18...+23) °С </strong><br> (бензол, этиловый спирт, этилацетат и др.);</span>
                </div>
              </v-card-text>
              <v-card-text class="d-flex align-center title font-weight-light pa-0">
                <v-icon size="150" color="red">mdi-roman-numeral-3</v-icon>
                <div>
                  <span>опасные при повышенной температуре воздуха,<br><strong>23 °С &lt;t <sub>всп</sub> &le; 60 °С </strong> <br> (уайт-спирит, керосин, сольвент, скипидар и др.).</span>
                </div>
              </v-card-text>
            </v-col>
            <v-col cols="5">
              <v-img
                lazy-src="img/material/flame-resourse2.svg"
                max-width="400"
                src="img/material/flame-resourse2.svg"
                class="ma-10 pa-5"
                contain
              ></v-img>
            </v-col>
          </v-row>

        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  data: () => ({
    value1: '- жидкости, имеющие 60°С < t всп <120 °С.',
    value2: '=< -18 °С',
    value3: '23 °С <'
  })
}
</script>
